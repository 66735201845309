body {
  font-family: 'NoirPro', sans-serif !important;
  background-color: #f5f5f5 !important;
}
.text-center {
  text-align: center;
}

.logo {
  margin-bottom: 1.5rem;
}

.nj-container {
  text-align: center;
  border-radius: 4px;
  padding: 30px 50px;
  margin-right: 0;
  display: block;
}

.nj-widget-base {
  /* background-color: white; */
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  /* color: #545454; */
  user-select: none;
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.nj-count-users-base {
  background-color: #37264a;
  color: white;
}

.nj-active-users-base {
  background-color: #f5b403;
  color: white;
}

.nj-count-coworkings-base {
  background-color: #0d4cf5;
  color: white;
}

.nj-count-waitinglist-base {
  background-color: #005777;
  color: white;
}

.nj-add-widget-min-height {
  min-height: 201px;
}

.nj-widget-number {
  font-size: 6rem;
  line-height: 6rem;
  color: white;
}

.nj-widget-text {
  font-size: 1rem;
  text-transform: uppercase;
  min-height: 1rem;
}

.nj-justify-content-between {
  justify-content: space-between;
}

.nj-widget-flex {
  display: flex;
  flex-direction: column;
}

.ant-table {
  background-color: #fff;
}
