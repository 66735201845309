.nj-image-previewer {
  height: 100px;
  width: 100px;
  background-color: @grey-6;
  border-radius: 5px;
}

.nj-image-previewer-preview {
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  color: white;
}

.nj-image-previewer-upload-button {
  height: 100px;
  width: 100px;
}
