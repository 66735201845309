/*ImageUpload Styles*/
.image-uploader {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 12px;
  background-size: cover;
  background-position: center;
}

.upload-button {
  margin: 0 !important;
  font-size: 10px;
  padding: 0;
  height: 24px;
  line-height: normal;
}

.left-up-btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.right-up-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
