.nj-horizontal-scrollbar {
  .ScrollbarsCustom-Scroller::-webkit-scrollbar {
    height: 0px;
  }
}

.nj-vertical-scrollbar {
  .ScrollbarsCustom-Scroller::-webkit-scrollbar {
    height: 0px;
  }
}
