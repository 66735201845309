.nj-circles-base-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-circles-base-card {
  background-color: white;
  border-radius: 5px;
  color: #262626;
  height: 180px;
}

.nj-change-link-color {
  color: #262626;
}

.nj-circles-base-side-card {
  background-color: white;
  border-radius: 5px;
}

.nj-circles-top-radius {
  border-radius: 5px 5px 0px 0px;
}

.nj-circles-bottom-radius {
  border-radius: 0px 0px 5px 5px;
}

.nj-circles-no-border {
  border-style: none;
}

.nj-circle-create-edit-base-card {
  background-color: white;
  border-radius: 5px;
}

.nj-circles-color-base-card {
  border-radius: 5px;
}

.nj-circles-letter-icon {
  border-radius: 5px;
  width: 48px;
  height: 48px;
  line-height: 24px;
}

.nj-circles-letter-icon-picture {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nj-verified-circle-check-icon {
  color: #30c9fc;
}

.nj-button-placement {
  height: 50px;
}

.nj-place-button {
  position: relative;
  top: -50px;
}

.nj-fixed-height-circles {
  height: 180px;
}

.nj-circles-form-container {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-circles-color-picker-container {
  & .ant-btn {
    height: 100px;
    width: 100px;
  }
}

.nj-circle-list-container {
  min-height: 30px;
}

.nj-circle-add-member-select-at {
  font-size: 0.75rem;
  line-height: 0.625rem;
}

.nj-circle-dot-separator {
  font-size: 4px;
}

.nj-cirle-info-join-small-text {
  font-size: 0.625rem;
}

.nj-circle-new-post {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-popover-hover:hover {
  color: @primary-color;
  transition: color 0.5s;
  transition-timing-function: ease-in-out;
}

.ant-select-selection__rendered {
  .ant-avatar-string {
    font-size: 0.625rem !important;
    line-height: 1.4rem !important;
  }
  .ant-avatar-circle {
    height: 20px !important;
    width: 20px !important;
  }
  .nj-avatar-is-selected {
    padding-top: 0rem !important;
  }
  .nj-circle-add-member-select-at {
    display: none;
  }
}
.nj-readmore-text {
  cursor: 'pointer';
  display: block;
}
