/*Tabs Styles*/
.@{class-prefix}-tabs-half {
  & .ant-tabs-bar {
    margin-bottom: 0;
  }

  & .ant-tabs-nav {
    display: block;

    & .ant-tabs-tab {
      margin: 0;
      width: 50%;
      text-align: center;
    }

    & .ant-tabs-ink-bar {
      width: 50%;
    }
  }
}

.@{class-prefix}-card-tabs {
  & .ant-card-head-title {
    text-transform: capitalize;
    font-size: @font-size-lg;
  }

  & .ant-card-extra {
    position: relative;
    z-index: 2;
  }

  & .ant-tabs {
    margin-top: -40px;

    @media screen and (max-width: @screen-xs-max) {
      margin-top: 0;
    }
  }

  & .ant-tabs-tab {
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 15px;
  }

  & .ant-tabs-tab-active {
    font-weight: @font-weight-normal;
  }

  & .ant-tabs-nav-container {
    font-size: @font-size-lg;
    text-align: center;

    @media screen and (max-width: @screen-xs-max) {
      text-align: left;
    }
  }

  & .ant-card-body {
    padding-top: @nj-card-padding-base - 20px;
  }
}

.@{class-prefix}-card-tabs-right {
  & .ant-tabs-nav-container {
    text-align: right;

    @media screen and (max-width: @screen-xs-max) {
      text-align: left;
    }
  }
}
