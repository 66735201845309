.nj-attachments-previewer-navigator {
  max-width: 200px;
  text-transform: none;
}

.nj-attachments-previewer-pdf-base-container {
  background-color: @grey-6;
  height: 606px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-attachments-previewer-pdf-base-container::-webkit-scrollbar {
  height: 3px;
  transition: height 0.7s ease-in-out;
}

.nj-attachments-previewer-pdf-base-container:hover::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.nj-attachments-previewer-pdf-base-container::-webkit-scrollbar-thumb {
  background-color: @grey-7 !important;
  border-radius: 0 !important;
}

.nj-attachments-previewer-pdf-controls {
  position: absolute;
  z-index: 69;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  .nj-zoom-controls-btn {
    height: auto;
    padding: 0;
    font-size: 12px;
  }
}

.nj-attachments-previewer-pdf-base-container:hover {
  .nj-attachments-previewer-pdf-controls {
    opacity: 1;
  }
}

.nj-attachments-previewer-pdf-disable-select {
  user-select: none;
}

.nj-attachments-previewer-pdf-controls-toggle-select {
  height: 26px;
  width: 26px;
  padding: 0;
  font-size: 12px;
}
