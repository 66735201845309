.user-account-container {
  display: flex;
  flex-flow: column;
  max-width: 750px;
  border-radius: 5px;
  margin: 20px;
  padding: 10px 25px;
  background-color: @white-color;

  & .ant-form-item-control-wrapper {
    width: 100%;
  }
  & .ant-input {
    min-width: 120px;
  }
}
.user-account-title {
  color: @primary-color;
  font-size: 18px;
  margin: 5px 0;
}
.user-account-personal-container {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  @media screen and (max-width: @screen-xs-max) {
    align-items: center;
    flex-flow: column;
  }
}
.user-account-image-container {
  padding: 10px;
  margin-right: 50px;
  text-align: center;
  & .ant-row {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (max-width: @screen-xs-max) {
    justify-content: center;
    margin-right: 0;
  }
}
.ant-input-wrapper.ant-input-group {
  margin: 12px 0;
}
.user-account-input-container {
  flex: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  & .user-account-inline {
    flex-flow: row wrap;
    & > .user-account-field:not(:last-child) {
      margin-right: 5px;
      padding-right: 5px;
      @media screen and (max-width: @screen-xs-max) {
        margin-right: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    flex-flow: column;
    width: 100%;
  }
}

.user-account-field {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin: 5px 0;
  & .ant-row,
  .ant-calendar-picker-input,
  .ant-select {
    position: relative;
    margin: 0;
    padding-left: 0.25rem;
  }
  & .ant-row.ant-form-item,
  .ant-calendar-picker {
    width: 100%;
  }
  @media screen and (max-width: @screen-xs-max) {
    min-width: 150px;
  }
}
.user-account-btn-container {
  display: flex;
  justify-content: flex-end;
}

.nj-field-has-helper {
  .ant-form-item {
    margin-bottom: 0;
  }
}
