.report-title {
  text-align: center;
}
.report-category-title {
  text-align: center;
}
.report-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 5px;
  background-color: @white-color;
}
.report-addmodal {
  width: 100%;
}
.category-button {
  width: inherit;
  text-align: left;
  align-items: wrap;
  @media screen and (max-width: @screen-xs-max) {
    font-size: @btn-font-size-xs;
  }
}
