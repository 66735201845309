.nj-help-desk-base-shadow {
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
}

.nj-help-desk-base-button {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  border: 0;
  height: auto;
  font-size: 1rem;
}

.nj-help-desk-base-card {
  background-color: white;
  border-radius: 5px;
}

.nj-tabs {
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 25%;
  }
  .ant-tabs-tab-prev {
    display: none;
  }
  .ant-tabs-tab-next {
    display: none;
  }
  .ant-tabs-nav-container-scrolling {
    padding-left: 0;
    padding-right: 0;
  }
}

.nj-help-desk-tab {
  min-width: 136px;
}

.nj-new-guest-modal-form {
  .ant-form-item-control-wrapper {
    width: 100%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-picker-input::placeholder {
    padding-left: 6px;
  }
  .ant-calendar-picker-input {
    padding-left: 6px;
  }
}

.ant-calendar-input {
  pointer-events: none;
  user-select: none;
}

.guest-tag {
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 5px;
  height: max-content;
  border-radius: 5px;
}

.guest-tag-green {
  background-color: @green-color;
  color: white;
}

.guest-tag-grey {
  background-color: @grey-5;
  color: white;
}

.guest-tag-yellow {
  background-color: @yellowish-orange;
  color: white;
}

.nj-front-desk-room-thumbnail {
  width: 50px;
  background-color: @grey-6;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nj-front-desk-greyed-text {
  color: @grey-6;
}

.nj-front-desk-room-select-currency {
  font-size: 0.5rem;
  line-height: 0.8rem;
}

.ant-select-selection-selected-value {
  .nj-hide-when-selected {
    display: none !important;
  }
}
