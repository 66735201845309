.nj-sidebar-icon-placement {
  width: 40px;
  svg {
    width: 14px;
    height: 16px;
  }
}

.ant-menu-inline-collapsed {
  .subMenu-link {
    span {
      display: none;
    }
  }
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      span {
        display: none;
      }
    }
  }
}

.ant-menu-inline .ant-menu-item {
  padding-right: 0 !important;
}

//
// .sidebar {
//   background-color: @grey-3 !important;
// }
//
// .nj-sidebar-content {
//   background-color: @grey-3 !important;
// }
//
// .ant-menu {
//   background-color: @grey-3 !important;
// }
//
// .nj-sidebar-content {
//   border-width: 0;
// }
