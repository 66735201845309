.nj-startups-base-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-startups-base-card {
  background-color: white;
  border-radius: 5px;
  color: black;
}

.nj-startups-message-button {
  border-radius: 0 0 5px 5px;
}

.nj-startups-text-black {
  color: black;
}

.nj-startups-card-min-height {
  min-height: 320px;
}

.nj-startups-plus-card-min-height {
  max-height: 356px;
}

.nj-startup-base-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-startup-base {
  color: black;
  background-color: white;
  border-radius: 5px;
}

.nj-startup-bio {
  font-weight: 200;
}

.nj-startup-data-padding {
  margin-left: 22px;
}

.nj-startup-card-name {
  font-weight: 500;
}

.nj-startup-social {
  font-size: 1.25rem;
}

.nj-startups-form-container {
  & .ant-form-item-control-wrapper {
    width: 100%;
    margin: 0 2px;
  }
  .ant-input-wrapper.ant-input-group {
    margin: 0;
  }
}

.nj-invite-people-to-startup:hover {
  color: @primary-color;
}

.nj-invite-people-to-startup-options:hover {
  color: @primary-color;
}

.nj-invite-people-to-startup-options {
  width: auto !important;
}

.nj-startups-please-name-this-class {
  width: fit-content;
}

.nj-startup-kick-button {
  .icon-button {
    margin: 0;
    opacity: 0;
  }
}

.nj-startup-team-member:hover {
  .icon-button {
    opacity: 1;
  }
}

.nj-startup-achievements-date {
  .ant-input {
    padding-left: 12px;
  }
}

.nj-add-achievement:hover {
  color: @primary-color;
}

.nj-startup-create-edit-achievement-display {
  height: 50px;
  border-bottom: solid 1px @grey-5;
}

.nj-achievement-description-display {
  font-weight: 300;
}

.nj-social-profile-avatar-display {
  font-size: 3rem !important;
}

.nj-startup-team-member button {
  padding: 0;
  margin: 0;
  border: 0;
  background: #fff;
}
