.ant-row {
  margin-right: 0;
  margin-left: 0;
}
.form-container {
  border-radius: 5px;
  padding: 20px 10px;
  background-color: @white-color;

  & .ant-row {
    margin-right: 0;
    margin-left: 0;
  }
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
  & .ant-input,
  .ant-input-number,
  .ant-input-number-input,
  .ant-select {
    min-width: 120px;
    margin: 0;
  }
  & .image-uploader {
    max-width: 480px;
  }
}
.form-input-container {
  & .input-label {
    margin: 0;
    text-transform: uppercase;
  }
}
.form-input-label {
  text-transform: uppercase;
  line-height: 41px;
}

.ant-calendar-picker-input.ant-input {
  padding: 0;
  padding-left: 4px;
  & .ant-calendar-range-picker-separator {
    vertical-align: unset;
  }
}
.form-inline-container {
  & .ant-col {
    padding: 0;
  }
  & .ant-col:not(:last-child) {
    padding-right: 5px;
  }
  & .ant-col:not(:first-child) {
    padding-left: 5px;
  }
}
