.nj-rooms-room-card-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-rooms-form-container {
  background-color: white;
  border-radius: 5px;
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-rooms-room-card-top {
  background-color: @grey-9;
  border-radius: 5px 5px 0 0;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nj-rooms-room-card-mid {
  background-color: white;
  color: black;
}

.nj-rooms-room-card-bottom {
  background-color: white;
  border-radius: 0 0 5px 5px;
  color: black;
}
.nj-rooms-add {
  width: 100%;
  height: 275px;
  margin: 0;
  @media screen and (max-width: @screen-xs-max) {
    height: 70px !important;
  }
}
.nj-rooms-room-card-pricing {
  color: @grey-7;
}

.nj-rooms-room-card-pricing-currency {
  font-size: 0.625rem;
}

.nj-rooms-room-card-edit-button-placement {
  height: 50px;
}

.nj-rooms-form-picture-upload-container {
  width: 100%;
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-rooms-form-pricing-text {
  line-height: 2.5rem;
}

.nj-rooms-duration-select-placement {
  width: 100px;
}

.nj-rooms-form-checkbox-container {
  height: 36px;
}

.nj-room-dsiplay-picture {
  border-radius: 5px;
}

.nj-room-display-price {
  & h2 {
    margin-bottom: 0;
  }
}

.nj-room-display-currency {
  font-size: 0.75rem;
}

.nj-room-reservations-reservations-listing {
  border: 1px solid @grey-5;
  border-radius: 5px;
  height: 240px;
  background-color: #f6f6f6;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.nj-room-reservations-reservation-display-card {
  background-color: white;
  border-radius: 5px;
  height: 80px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  align-items: center;
}

.nj-room-reservations-reservation-display-card-remove-button {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: normal;
}

.nj-room-reservation-date-picker {
  & .ant-calendar-picker-input {
    padding-left: 12px;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}

.nj-rooms-reservation-interval {
  color: @grey-7;
  font-size: 0.75rem;
}

.nj-reservation-number-input {
  & .ant-input-number {
    margin: 0;
  }
}

.nj-time-selector-left-button {
  height: 38px;
  border-radius: 5px 0 0 5px;
}

.nj-time-selector-right-button {
  height: 38px;
  border-radius: 0 5px 5px 0;
}

.nj-room-calendar-display-container {
  & .rbc-time-header {
    & .rbc-row-content {
      display: none;
    }
  }
  & .rbc-time-content {
    border: none;
  }
  & .rbc-time-view {
    border-bottom: none;
    border-radius: 5px;
  }
  & .rbc-timeslot-group:first-child {
    border-radius: 5px 5px 0 0; // not correct
  }
  & .rbc-timeslot-group:last-child {
    border-radius: 0 0 5px 5px; // not correct
  }
  & .rbc-event {
    border: none;
    background-color: @primary-color;
    // box-shadow: 0px 17px 26px -9px rgba(13, 75, 245, 1);
    pointer-events: all;
  }
  & .rbc-events-container {
    margin: 0;
    pointer-events: none;
  }
  & .rbc-today {
    background-color: rgba(0, 0, 0, 0);
  }
  & .rbc-active {
    border-color: none !important;
    background-color: @primary-color !important;
  }
  & button:hover {
    color: @primary-color;
    background-color: white;
    border-color: inherit;
  }
  & .rbc-label {
    user-select: none;
  }
  & .rbc-slot-selection {
    background-color: @primary-color;
  }
  .rbc-toolbar {
    flex-wrap: nowrap;
  }
}

.nj-duration-select {
  min-width: 200px;
}

.nj-room-display-cover-picture {
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nj-amnities-containers {
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding: 16px;
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.nj-rooms-amnety {
  & svg:hover {
    outline: @primary-color;
  }
}

.nj-book-email {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-room-solt-disabled {
  background-color: @grey-3;
  border-top: 1px solid @grey-3 !important;
}

.nj-room-solt-disabled:hover {
  background-color: #738290;
  border-top: 1px solid #738290 !important;
}

.nj-room-slot-events-animations {
  transition: background-color 0.125s ease-out;
}

.nj-room-slot-events {
}

.nj-room-slot-events:hover {
  background-color: #f5fff4;
  border-top: 1px solid #f5fff4 !important;
}

.nj-room-slot-forbidden {
  background-color: @grey-3;
  border-top: 1px solid @grey-3 !important;
}

.nj-room-slot-forbidden:hover {
  background-color: #b6465f;
  border-top: 1px solid #b6465f !important;
}

.rbc-date-cell.rbc-now {
  color: @primary-color;
}

.nj-amenity-selector-checkbox {
  border-radius: 5px;
  border: 1px solid @grey-5;
  min-width: 48px;
  min-height: 48px;
  padding: 8px;
}

.nj-amenity-selector-checkbox-checked {
  border-color: @primary-color;
  svg {
    fill: @primary-color;
    storke: @primary-color;
  }
}

.ant-checkbox-wrapper:hover {
  .nj-amenity-selector-checkbox {
    border-color: @primary-color;
  }
  svg {
    fill: @primary-color;
  }
}

.nj-amenities-selection {
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox + span {
    padding: 0;
  }
}

.nj-create-room-max-booking {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.nj-room-cancel-select-placement {
  min-width: 160px;
}

.nj-booking-info-alert {
  color: @danger-color;
}

.nj-and-some-more-button {
  color: @primary-color !important;
}

.nj-dep-rooms-item {
  min-width: 300px;
}

.nj-rooms-room-card-diplay {
  box-shadow: 0 34px 63px -35px rgba(92, 92, 92, 1);
  transition: all 0.7s;
  user-select: none;
}

.nj-rooms-room-card-diplay:hover {
  box-shadow: 0 34px 73px -16px rgba(92, 92, 92, 0.7);
  transform: translateY(-10px);
}

.nj-rooms-toggle-pin-btn {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: 20px;
  line-height: 17px;
}

.nj-rooms-toggle-pin-btn:hover {
  background-color: transparent;
}

.nj-rooms-toggle-pin-btn:focus {
  background-color: transparent;
}

.nj-rooms-regen-pin-btn {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: 20px;
  line-height: 17px;
  text-transform: none;
}

.nj-rooms-regen-pin-btn:hover {
  background-color: transparent;
}

.nj-rooms-regen-pin-btn:focus {
  background-color: transparent;
  color: @text-color;
}

.nj-rooms-regen-pin-btn-clicked {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: 20px;
  line-height: 17px;
  color: @rooms-confirm-pin-regen;
  text-transform: none;
}

.nj-rooms-regen-pin-btn-clicked:hover {
  background-color: transparent;
}

.nj-rooms-regen-pin-btn-clicked:focus {
  background-color: transparent;
  color: @rooms-confirm-pin-regen;
}
