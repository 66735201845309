.tickets-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 5px;
  background-color: @white-color;
}
.tickets-addmodal {
  width: 100%;
}
.tickets-btn {
  width: 100%;
  height: 70px;
  background-color: transparent;
}

.discussion-btn-container {
  display: flex;
  flex-flow: column nowrap;
}
.info-btn-container {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  align-items: center;
}
.reply-modal-footer {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: space-between;
  & .ant-btn {
    margin-bottom: 22px;
    @media screen and (max-width: @screen-lg-max) {
      margin-bottom: 15px;
    }
  }
  & .ant-form-item {
    flex: 1;
  }
}
.add-category-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.tickets-select {
  width: 200px;
  & .ant-select-arrow {
    left: 11px;
    right: unset;
  }
  & .ant-select-selection-selected-value {
    margin-left: 22px;
    & .ticket-checkbox {
      display: none;
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
  }
}
.tickets-icon-container {
  display: flex;
  max-width: 200px;
  min-height: 110px;
  margin: auto;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}
.tickets-title {
  text-align: center;
}
.tickets-arrow-btn {
  margin-left: 1.5rem;
  float: left;
  @media screen and (max-width: @screen-md-max) {
    margin-left: 0;
  }
}
.tickets-icon {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.tickets-disabled-icon {
  color: @grey-color;
  background-color: @grey-4;
}
.tickets-title-lg {
  @media screen and (max-width: @screen-xs-max) {
    display: none;
  }
}
.tickets-title-xs {
  display: none;
  @media screen and (max-width: @screen-xs-max) {
    display: block;
  }
}
