.nj-people-base-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-people-base-card {
  background-color: white;
  border-radius: 5px;
  color: black;
}

.nj-people-message-button {
  border-radius: 0 0 5px 5px;
}

.nj-people-text-black {
  color: black;
}

.nj-people-card-min-height {
  min-height: 320px;
}

.nj-person-base-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}

.nj-person-base {
  color: black;
  background-color: white;
  border-radius: 5px;
}

.nj-person-bio {
  font-weight: 200;
}

.nj-person-data-padding {
  margin-left: 22px;
}

.nj-person-card-name {
  font-weight: 500;
}

.nj-person-social {
  font-size: 1.25rem;
}
