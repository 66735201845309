.nj-plans-plan-info {
  background-color: @yellowish-orange;
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
  width: 100%;
}

.nj-plans-plan-info-box {
  width: 100%;
  padding: 0 12px;
}

.nj-plans-plan-info-button {
  border: none;
  color: @yellowish-orange;
  margin: 12px;
}

.nj-plans-plan-info-button:hover {
  border-color: white;
  border-style: solid;
  border-width: 1px;
  color: white;
  background-color: @yellowish-orange;
}

.nj-plans-plan-info-left-box {
  line-height: 60px;
}

.nj-plans-plan-info-right-box {
  padding-right: 0;
}

.nj-plans-plan-create-edit {
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
  border-radius: 5px;
  color: black;
}

.nj-plans-plan-display {
  background-color: @primary-color;
  box-shadow: 0 34px 63px -35px rgba(10, 91, 135, 1);
  border-radius: 5px;
  color: white;
  transition: all 0.7s;
  user-select: none;
}

.nj-plans-plan-display:hover {
  box-shadow: 0 34px 73px -16px rgba(10, 91, 135, 0.7);
  transform: translateY(-10px);
}

.nj-plans-display-box {
}

.nj-plans-plan-display-badge-placement {
  float: right;
}

.nj-plans-plan-display-current-badge {
  background-color: white;
  color: @primary-color;
  font-size: 0.75rem;
  padding: 2px 6px;
  width: fit-content;
  border-radius: 5px;
}

.nj-plans-plan-display-plan-name {
  width: 100%;
  font-size: 1rem;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.nj-plans-plan-display-plan-price {
  font-size: 1.5rem;
}

.nj-plans-plan-display-plan-price-sup {
  font-size: 1rem;
  line-height: 1rem;
}

.nj-plans-plan-display-plan-price-digits {
  line-height: 2.5rem;
  font-size: 3.5rem;
}

.nj-plans-plan-display-plan-price-decimal {
  font-size: 1rem;
  line-height: 1rem;
}

.nj-plans-plan-display-plan-price-duration {
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.nj-plans-plan-display-plan-price-expiration {
  color: white;
  font-weight: semi-bold;
}

.nj-plans-plan-display-plan-details-button {
  color: white;
  margin: 0;
  font-size: 0.75rem;
  border-radius: 0;
}

.nj-plans-plan-display-plan-details-button:hover {
  color: white;
  border-bottom-color: white;
  border-bottom-width: 1px;
}

.ant-modal-footer {
  border-top: none;
  padding-right: 0;
}

.nj-plan-display-name {
  font-size: 1.125rem;
}

.nj-plans-plan-display-column-bold-text {
  font-weight: 600;
}

.nj-plans-plan-display-plan-discount {
  font-size: 0.75rem;
  line-height: 1.125rem;
}
// plan edit
.nj-plan-edit-time-table-prefix {
  height: 38px;
  width: 60px;
  background-color: #f6f6f6;
  border: solid;
  border-width: 1px;
  border-radius: 5px 0 0 5px;
  border-color: @lightGrey-color;
  padding: 10px;
  text-align: center;
}

.nj-plan-edit-time-table-suffix-button {
  height: 38px;
  width: 50%;
  border-radius: 0 5px 0 0;
  border-bottom: 0;
  border-left: 0;
}

.nj-plan-edit-time-table-suffix-button-no-multiple {
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0) 0%,
    rgba(246, 246, 246, 0.7707457983193278) 14%,
    rgba(246, 246, 246, 1) 28%
  );
}

.nj-plan-edit-time-table-suffix-button-no-multiple:hover {
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0) 0%,
    rgba(246, 246, 246, 0.7707457983193278) 14%,
    rgba(246, 246, 246, 1) 28%
  );
}

.nj-plan-edit-time-table-prefix-button-active {
  height: 38px;
  width: 50%;
  border-radius: 5px 0 0 0px;
  border-right: 0;
  border-bottom: 0;
}

.nj-plan-edit-time-table-prefix-button-disabled {
  height: 38px;
  width: 50%;
  border-radius: 5px 0 0 0px;
  border-right: 0;
  border-bottom: 0;
  background-color: #f6f6f6;
}

.nj-time-table-time-picker-round-bottom {
  border-radius: 0 0 5px 5px !important;
}

.nj-plan-edit-time-table-prefix-button-disabled:hover {
  background-color: #f6f6f6;
}

.nj-plan-edit-time-table-prefix-plus {
  height: 38px;
  width: 59px;
  text-align: center;
}

.nj-time-table-input {
  & .ant-input {
    border-radius: 0;
  }
  // max-width: 500px;
  .ant-calendar-range-picker-input {
    width: 49% !important;
  }
}

.nj-time-table-time-picker-round-bottom {
  & .ant-input {
    border-radius: 0 0 5px 5px;
  }
}

.nj-plan-edit-reset-plan-btn {
  & .icon-button {
    margin-left: 0;
  }
}

.nj-plan-form {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-plans-plan-display {
  & .ant-row {
    margin-left: 0;
    margin-right: 0;
  }

  & .ant-input-number {
    margin: 0;
    width: 100%;
  }
}

.nj-time-table-multiple-bottom {
  & .ant-calendar-picker-input {
    border-radius: 0 0 5px 5px;
    border-left: 1px solid @grey-5;
    border-right: 1px solid @grey-5;
    border-top: 0;
  }
  .ant-calendar-picker-clear {
    // display: none;
  }
}

.nj-has-multiple {
  & .ant-form-item {
    margin-bottom: 0;
  }

  & .ant-form-item-control {
    line-height: normal;
  }
}

.nj-plan-time-table-minus-button {
  padding: 0;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
}

.nj-plan-time-table-plus-button {
  padding: 0;
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 0 5px 5px 0;
}

.nj-plan-edit-pricing {
  & .ant-form-item {
    margin-bottom: 0;
  }
}

.nj-plan-edit-room-display-card {
  background-color: white;
  height: 80px;
  border-bottom: solid 1px @grey-4;
}

.nj-plan-edit-room-display-card-remove-button {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: normal;
}

.nj-plans-add {
  min-height: 220px;
}

.nj-edit-plan-button {
  background-color: rgba(0, 0, 0, 0);
  border-color: white;
  color: white;
  border-radius: 100px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  height: 24px;
}

.nj-room-preview {
  height: 45px;
  width: 80px;
  border-radius: 5px;
  background-color: @grey-5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nj-time-table-time-picker {
  .ant-calendar-picker-clear {
    display: none;
  }
}

.nj-time-table-full-day-display {
  width: 80px;
}
