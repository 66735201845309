/*Pickers Styles*/
.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important;
}

.@{class-prefix}-alpha-pickers {
  position: relative;
  min-height: 250px;
}

.ant-calendar-footer .ant-calendar-ok-btn {
  margin-bottom: 0;
}

.time-picker {
  & .ant-calendar-header {
    display: none;
  }
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  padding-top: 0;
}
