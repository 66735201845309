.lostfound-card {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  background-color: @white-color;
  border-radius: 8px;
  height: 210px;
  margin: 15px 0;
  overflow: hidden;
  cursor: pointer;
  & .ant-btn {
    margin: 0 20px;
    margin-bottom: 20px;
  }
  &.lostfound-found {
    border-left: solid @green-color 5px;
  }
  &.lostfound-lost {
    border-left: solid @danger-color 5px;
  }
}
.nj-widget-base {
  .lostfound-card {
    box-shadow: none;
    border-radius: 0;
  }
}
.lostfound-image {
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  height: 130px;
  min-height: 130px;
  width: 100%;
  &.modal {
    min-height: 220px;
  }
}
.lostfound-container {
  flex: 1;
  padding: 5px;
}
.lostfound-info {
  display: inline;
}

.lostfound-card-title {
  overflow: hidden;
  max-height: 34px;
}
