.feed-icon {
  color: @white-color;
}
.feed-title {
  color: @white-color;
  margin: 0;
}

.feed-title-container {
  border-radius: 5px;
  background-color: #00798a;
}
.feed-post-container {
  background-color: @white-color;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);

  & .ant-col.ant-form-item-control-wrapper {
    width: 100%;
  }
  & .ant-row.ant-form-item {
    margin-bottom: 0;
  }
}

.feed-comments-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 230px;
}

.feed-setting-button {
  width: 10px !important;
  float: right;
}
