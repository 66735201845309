.account-container {
  padding: 0 20px;
  margin-top: 60px;
}
.account-card {
  background-color: @white-color;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
}
.member-card {
  background-color: @white-color;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
}
.account-card .ant-avatar-string {
  font-size: 60px;
}
.member-card-component {
  display: flex;
  flex-flow: column nowrap;
  margin: 5px 0;
}
.member-card-title {
  font-size: 16px;
  margin: 5px 0;
}
.member-card-text {
  font-size: 12px;
}
.member-card-button-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.member-card-button {
  margin: 5px 1px;
  padding: 0 5px;
  min-width: 160px;
  flex: 1;
}
.member-card-button .ant-btn {
  margin: 0;
}
.profile-card-info {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 95px;
}
.profile-card-avatar-container {
  position: absolute;
  border: solid @white-color 8px;
  border-radius: 50%;
  top: -75px;
}
.profile-card-avatar {
  width: 100%;
  height: 100%;
}
.profile-card-username {
  font-size: 32px;
  color: @primary-color;
}
.profile-card-text {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.pending-memberships-badge {
  .ant-badge-count {
    background-color: @primary-color;
    border-width: 0;
  }
}
