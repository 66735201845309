.nj-calendar-card {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
  display: block;
  overflow: auto;
  height: 100%;
}

.nj-calendar-scroll-wrapper {
  min-width: 1545px;
  min-height: 1152px;
  display: block;
  overflow: auto;
}

.nj-calendar-event {
  border-left-width: 3px !important;
  border-right-width: 0 !important;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  border-radius: 0;
  color: white;
  // text-shadow: 0px 7px 13px rgba(150, 150, 150, 0.46);
}

.nj-calendar-event-guest {
  border-color: @guest-event !important;
  background-color: @guest-event-light;
  box-shadow: none;
  transition: box-shadow 0.5s;
}

.nj-calendar-event-guest.rbc-selected {
  border-color: @guest-event !important;
  background-color: @guest-event-light;
  box-shadow: 0px 5px 33px -10px rgba(0, 0, 0, 0.8);
}

.nj-calendar-event-expected-guest {
  border-color: @expected-guest-event !important;
  background-color: @expected-guest-event-light;
  box-shadow: none;
  transition: box-shadow 0.5s;
}

.nj-calendar-event-expected-guest.rbc-selected {
  border-color: @expected-guest-event !important;
  background-color: @expected-guest-event-light;
  box-shadow: 0px 5px 33px -10px rgba(0, 0, 0, 0.8);
}

.nj-calendar-event-delivery {
  border-color: @delivery-event !important;
  background-color: @delivery-event-light;
  box-shadow: none;
  transition: box-shadow 0.5s;
  color: @grey-9;
}

.nj-calendar-event-delivery.rbc-selected {
  border-color: @delivery-event !important;
  background-color: @delivery-event-light;
  box-shadow: 0px 5px 33px -10px rgba(0, 0, 0, 0.8);
}

.nj-calendar-event-booking {
  border-color: @room-booking-event !important;
  background-color: @room-booking-event-light;
  box-shadow: none;
  transition: box-shadow 0.5s;
  color: @grey-9;
}

.nj-calendar-event-booking.rbc-selected {
  border-color: @room-booking-event !important;
  background-color: @room-booking-event-light;
  box-shadow: 0px 5px 33px -10px rgba(0, 0, 0, 0.8);
}

.nj-calendar-event-event {
  border-color: @event-event !important;
  background-color: @event-event-light;
  box-shadow: none;
  transition: box-shadow 0.5s;
}

.nj-calendar-event-event.rbc-selected {
  border-color: @event-event !important;
  background-color: @event-event-light;
  box-shadow: 0px 5px 33px -10px rgba(0, 0, 0, 0.8);
}

.nj-calendar-card {
  .rbc-toolbar {
    button:hover {
      color: white;
      background-color: @primary-color;
      border-color: @primary-color;
    }
    button:active {
      color: white;
      background-color: @primary-color;
      border-color: @primary-color;
    }
    button:focus {
      color: white;
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
  .rbc-today {
    background-color: white;
  }
  .rbc-current-time-indicator {
    background-color: @primary-color;
    height: 2px;
  }
  .rbc-time-content {
    border-top-width: 1px;
  }
  .rbc-header.rbc-today {
    background-color: @primary-color;
    color: white;
    font-weight: 350;
  }
  .rbc-header {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
  .rbc-event-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .rbc-time-view {
    border-bottom-width: 0;
  }
}

.nj-calendar-slot {
  border-color: rgba(0, 0, 0, 0) !important;
}

.nj-calndar-data-square {
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
}

.nj-calendar-scrollbar-height-property {
  min-height: 1162px;
}

@media screen and (min-width: 992px) {
  .nj-calendar-modal-wrapper-wide {
    left: 140px;
  }
  .nj-calendar-modal-wrapper-narrow {
    left: 340px;
  }
}

@media screen and (max-width: 991px) {
  .nj-calendar-scrollbar-height-property {
    min-height: 1200px;
  }
}
