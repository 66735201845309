.nj-events-card-shadow {
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}
.nj-events-form-container {
  background-color: white;
  border-radius: 5px;
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.events-card {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  background-color: @white-color;
  height: 375px;
  margin: 15px 0;
  overflow: hidden;
}
.events-image {
  background-color: @grey-9;
  border-radius: 5px;
  height: 160px;
  max-height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.events-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.events-add {
  width: 100%;
  height: 375px;
  margin: 15px 0;
  @media screen and (max-width: @screen-xs-max) {
    height: 70px;
  }
}
.events-time {
  display: inline;
  margin: 5px 0;
  font-size: 14px;
  line-height: 16px;
  color: @orange-color;
}
.events-checkbox-container {
  padding: 5px;
  display: flex;
  flex-flow: column;
  text-transform: capitalize;
  & .ant-checkbox-wrapper {
    margin: 3px 0;
  }
}
.events-top-button {
  width: 80px;
  height: 36px;
  border-radius: 4px;
  position: absolute;
  top: 15px;
  left: 16px;
  -webkit-box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  z-index: 3;
  text-align: center;
  line-height: 36px;
  text-transform: uppercase;
}
.events-sold-out {
  background-color: @grey-6;
  color: @white-color;
}
.events-privacy {
  float: right;
  color: @grey-7;
}
.events-free {
  background-color: @white-color;
}
.edit-event-visibility {
  & .ant-col {
    padding: 0;
  }
}
.event-container.ant-col-xl-20 {
  margin: 0 auto;
}
.event-form {
  & .ant-btn {
    margin: 1.5rem;
  }
}
.event-picture-container {
  padding-left: 0;
}
.event-picture {
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  height: 300px;
  min-height: 150px;
  width: 100%;
}
.event-description-container {
  background-color: @white-color;
  border-end-end-radius: 5px;
  & .ant-progress-bg {
    background-color: @primary-color;
  }
  & .ant-progress-inner {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
}
.reset-event-btn {
  & .icon-button {
    margin-left: 0;
  }
}
.events-location {
  color: @grey-7;
  padding-bottom: 6px;
}
.events-price {
  color: @grey-7;
}
.event-title,
.events-title {
  text-transform: capitalize !important;
  font-size: 20px;
  font-weight: 500;
}
.events-title,
.nj-ticket-type-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-transform: capitalize;
}
.event-description {
  white-space: pre-line;
  font-size: 16px;
}
.event-time {
  color: @grey-7;
}
.event-ticket-radio-btn {
  margin-top: 40px;
  @media screen and (max-width: @screen-xs-max) {
    margin-top: 0;
  }
}
.tickets-addmodal {
  & .ant-row.ant-form-item {
    width: 100%;
  }
  & .ant-calendar-picker-input.ant-input {
    padding-left: 10px;
  }
}
.nj-ticket-type-visibility {
  font-size: 12px;
  display: block;
  color: @grey-7;
}
.ticket-container {
  margin: 10px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 9px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  &.ticket-container-registred {
    border-left: solid @primary-color 5px;
  }
}
.tickets-list {
  font-weight: @font-weight-light;
}
.ticket-quantity-select {
  width: 100px;
}
.autocomplete-dropdown-container {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
}
