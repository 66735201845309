/*FileUpload Styles*/
.file-uploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  min-width: 175px;
}

.aligned {
  flex-direction: row;
  align-items: center;
}

.file-upload-prefix {
  display: flex;
  align-items: center;
}

.file-upload-label {
  margin: 5px;
  text-transform: uppercase;
}

.uploaded-file-container {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: @grey-5;
  min-height: 42px;
  max-width: 1000px;
  min-width: 200px;
  padding: 10px;
  margin: 2px;
}

.upload-file-element {
  color: black;
  display: flex;
  align-items: center;
  line-height: 1.3;
  height: 20px;
  padding-left: 5px;
  padding-right: 0.25rem;
  margin: 2px 5px;
  border-radius: 5px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: @grey-5;
}

.upload-file-name {
  height: 18px;
  overflow: hidden;
  user-select: none;
}
