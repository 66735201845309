.nj-reports-main-card {
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
  height: 200px;
  color: white;
  h1 {
    color: white;
  }
  a {
    color: white;
    font-size: 1rem;
    .nj-reports-link-selector {
      transition: all 0.7s;
    }
  }
  a:hover {
    .nj-reports-link-selector {
      transform: translateX(10px);
    }
  }
}

.nj-reports-card-bg-color-1 {
  background-color: @card-color-1;
}

.nj-reports-card-bg-color-2 {
  background-color: @card-color-2;
}

.nj-reports-card-bg-color-3 {
  background-color: @card-color-3;
}

.nj-reports-card-bg-color-4 {
  background-color: @card-color-4;
}
