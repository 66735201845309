.vendors-add {
  height: 120px;
  margin: 15px 0 !important;
  padding: 0;
}

.nj-invoice-card-shadow {
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
}

.nj-invoice-form-container {
  background-color: white;
  border-radius: 5px;

  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-invoice-header {
  font-size: 0.875rem;
  display: block;
}
.nj-invoice-header .input-label {
  display: inline-block;
  width: 25%;
}
.nj-invoice-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: @grey-8;
}

.nj-invoice-value {
  font-size: 1rem;
}
.nj-invoice-client-box {
  height: 100%;
  border: 1px solid @grey-5;
  padding: 12px;
  margin-bottom: 15px;
}
.nj-invoice-dragging {
  background: #ddd;
  background-image: repeating-linear-gradient(
    30deg,
    rgba(#fff, 0.2),
    rgba(#fff, 0.2) 30px,
    transparent 0,
    transparent 60px
  );
}

.nj-invoice-item-dragging {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(92, 92, 92, 0.12);
}

.nj-invoice-delete-item {
  cursor: pointer;
}
.nj-invoice-logo {
  height: 75px;
}
.nj-invoice-drag-item {
  cursor: pointer;
}
.nj-invoice-value .ant-form-item {
  margin-bottom: 0;
}

.nj-finance-card-base {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
  .ant-calendar-picker-input.ant-input::placeholder {
    padding: 0 !important;
  }
}

.nj-finance-side-list {
  .ant-menu-item-selected {
    background-color: @primary-color !important;
    color: white !important;
    .nj-finance-item-title {
      font-weight: 600;
    }
    .nj-finance-item-sub {
      color: white !important;
    }
  }
  .ant-menu-item {
    height: 83px;
    line-height: normal;
    padding: 1rem !important;
    .nj-finance-item-sub {
      font-weight: 200;
      color: @grey-6;
    }
  }
  .ant-menu-item:hover {
    .nj-finance-item-sub {
      color: @primary-color;
    }
  }
  .ant-menu-item:after {
    border-right: 0;
  }
  height: 100vh;
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.nj-finance-side-list::-webkit-scrollbar-track {
  background-color: white !important;
}

.nj-finance-side-list {
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-finance-details-title {
  font-weight: 500;
}

.nj-finance-details-section-title {
  color: @grey-6;
}

.nj-finance-details-container {
  // background-color: white;
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 700px;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-finance-details-container-w-100 {
  // background-color: white;
  max-height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-finance-details-container-w-100-options {
  // background-color: white;
  max-height: calc(100vh - 295px);
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-finance-details-container::-webkit-scrollbar-track {
  background-color: white !important;
}

.nj-finance-details-container-w-100::-webkit-scrollbar-track {
  background-color: white !important;
}

.nj-finance-side-list-contacts {
  .ant-menu-item-selected {
    background-color: @primary-color !important;
    color: white !important;
    .nj-finance-item-title {
      font-weight: 600;
    }
    .nj-finance-item-sub {
      color: white !important;
    }
  }
  .ant-menu-item {
    .nj-finance-item-sub {
      font-weight: 200;
      color: @grey-6;
    }
  }
  .ant-menu-item:hover {
    .nj-finance-item-sub {
      color: @primary-color;
    }
  }
  .ant-menu-item:after {
    border-right: 0;
  }
}

.nj-finance-dtails-container-contact {
  // background-color: white;
  max-width: 700px;
}

.nj-finance-contacts-bolder {
  font-weight: 500;
}

.nj-finance-details-value-display {
  font-size: 1.5rem;
  color: @primary-color;
}

.nj-finance-assets-category-select {
  width: 200px;
  & .ant-select-selection {
    height: 36px;
  }
  & .ant-select-selection-selected-value {
    .nj-finance-assets-category-select-icon {
      display: none;
    }
  }
  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
  }
}

.nj-finance-asset-picture-display {
  width: 100%;
  padding-top: 56.25%;
  background-color: @grey-7;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
}

.nj-finance-remove-mb {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.nj-finance-expense-account-name-container {
  background-color: @primary-color;
  border-radius: 5px;
  width: max-content;
  color: white;
}

.nj-finance-invoice-payment-status-unpaid {
  border-radius: 5px;
  background-color: @red-color;
  color: white;
}

.nj-finance-invoice-payment-status-paid {
  border-radius: 5px;
  background-color: @primary-color;
  color: white;
}

.nj-finance-invoice-payment-status-partial {
  border-radius: 5px;
  background-color: @yellow-color;
  color: white;
}

.nj-invoices-view-balance-due {
  font-weight: 600;
}

.nj-invoices-view-sub-table-bolder {
  font-weight: 600;
}

.nj-invoics-view-fixed-left-bloc {
  width: 200px;
}

.nj-invoice-view-items {
  .ant-table-tbody {
    tr {
      td {
        border-bottom: 0 !important;
      }
    }
  }
}

.nj-finance-return-button {
  border: 0;
  box-shadow: none;
}

.nj-finance-scrollable-table {
  .ant-table-body {
    scrollbar-color: @grey-8 white;
    scrollbar-width: thin;
  }
  .ant-table-body::-webkit-scrollbar {
    background-color: white !important;
    height: 3px;
  }
  .ant-table-body::-webkit-scrollbar-track {
    background-color: white !important;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: @grey-7 !important;
  }
}

.nj-finance-invoice-container {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.nj-client-details-link {
  color: @primary-color !important;
}

.nj-finance-nav-back-button {
  text-transform: uppercase !important;
  color: black;
  transition: color 0.7s ease-in-out !important;
}

.nj-finance-nav-back-button:hover {
  text-transform: uppercase !important;
  color: @primary-color;
}

.nj-invoice-add-client {
  border: 0;
  box-shadow: none;
}

.nj-invoice-lighter-text {
  font-weight: 200;
}

.nj-invoice-items-header {
  border-top: 1px solid @grey-5;
  border-bottom: 1px solid @grey-5;
}

.nj-invoice-items-scrollable {
  overflow-x: scroll;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-invoice-items-min-width {
  min-width: 1030px;
}

.nj-invoice-items-scrollable::-webkit-scrollbar {
  background-color: white !important;
  height: 3px;
}
.nj-invoice-items-scrollable::-webkit-scrollbar-track {
  background-color: white !important;
}
.nj-invoice-items-scrollable::-webkit-scrollbar-thumb {
  background-color: @grey-7 !important;
}

.nj-finance-text-red {
  color: @red-color;
}

.nj-finance-unset-line-height {
  line-height: unset !important;
}

.nj-finance-disabled-text {
  color: @grey-6;
}

.nj-finance-return-button:disabled,
.nj-finance-return-button[disabled] {
  background-color: rgba(0, 0, 0, 0);
}

.nj-finance-return-button[disabled]:hover {
  background-color: rgba(0, 0, 0, 0);
}

.nj-finance-attachment-previewer-navigator {
  max-width: 200px;
  text-transform: none;
}

.nj-finance-attachment-pdf-base-container {
  background-color: @grey-6;
  height: 606px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: @grey-8 white;
  scrollbar-width: thin;
}

.nj-finance-attachment-pdf-base-container::-webkit-scrollbar {
  height: 3px;
  transition: height 0.7s ease-in-out;
}

.nj-finance-attachment-pdf-base-container:hover::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.nj-finance-attachment-pdf-base-container::-webkit-scrollbar-thumb {
  background-color: @grey-7 !important;
  border-radius: 0 !important;
}

.nj-finance-pdf-controls {
  position: absolute;
  z-index: 69;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  .nj-zoom-controls-btn {
    height: auto;
    padding: 0;
    font-size: 12px;
  }
}

.nj-finance-attachment-pdf-base-container:hover {
  .nj-finance-pdf-controls {
    opacity: 1;
  }
}

.nj-finance-attachments-pdf-disable-select {
  user-select: none;
}

.nj-pdf-controls-toggle-select {
  height: 26px;
  width: 26px;
  padding: 0;
  font-size: 12px;
}

.nj-finance-details-notes {
  max-width: 700px;
}

@media screen and (max-width: 455px) {
  .nj-finance-options {
    span {
      display: none;
    }
  }
}

.nj-finance-side-list {
  box-shadow: 14px 0px 39px -16px rgba(0, 0, 0, 0.1) !important;
  .ant-menu-item {
    border-bottom: 1px solid #e8e8e8;
  }
}

.nj-finance-side-list-contacts {
  box-shadow: 14px 0px 39px -16px rgba(0, 0, 0, 0.1) !important;
  .ant-menu-item {
    border-bottom: 1px solid #e8e8e8;
  }
}
