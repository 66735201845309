/*ListingCard Styles*/
.list-card-container {
  width: 100%;
  min-width: 375px;
  height: 200px;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: @white;
  box-shadow: 0px 0px 16px 0px rgba(92, 92, 92, 0.12);

  display: flex;
  flex-flow: row;
}
.list-card-text-container {
  display: block;
  width: 100%;

  font-family: 'NoirPro';
}
.list-card-button-container {
  min-width: 210px;
}
.list-card-core-container {
  max-width: 750px;
  overflow: hidden;

  display: flex;
  flex-flow: column;
}
.list-card-thumbnail {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  margin: 0 20px;
  overflow: hidden;
}
.list-card-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
}
.list-card-description {
  font-size: 14px;
  margin: 5px;
  max-height: 36px;
  overflow: hidden;
}
