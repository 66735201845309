.container-modal {
  & .ant-form-item-control-wrapper {
    width: 100%;
  }
}

.antd-img-crop-modal {
  .ant-modal-footer {
    padding: 0.125rem 1.375rem 0.5rem;
  }
}
