/*Alert Styles*/
.@{class-prefix}-custom-sweet-alert {
  & h2 {
    color: @white-color;
  }
}

.notification {
  .border-radius(@border-radius-sm);

  & .title {
    font-size: (@font-size-lg + 2);
    font-weight: @font-weight-medium;
    color: @white-color;
  }
}

.@{class-prefix}-sweet-alert-top-space {
  padding-top: @size-40 !important;
}
